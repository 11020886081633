<template>
  <div v-if="!error">
    <div v-for="(tags, category) in groupedTags" :key="category">
      <br />
      <strong>{{ $t(`fleetProfile.tagsCategory.${category}`) }}</strong>
      <b-form-checkbox-group
        v-model="form.select"
        :options="tags"
        class="mt-2"
        @input="setTags"
      />
    </div>
  </div>
  <div v-else>
    <b-alert show variant="warning" class="info">
      Não foi possivel carregar as Tags
      <b-button class="bg-warning" size="sm" @click="reloadWindow">
        <i class="fa fa-refresh" />
      </b-button>
    </b-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    carId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      form: {
        select: []
      },
      error: false
    }
  },
  computed: {
    ...mapGetters('tags', ['tagsOptions', 'tagsSelected']),
    groupedTags() {
      return this.tagsOptions.reduce((acc, tag) => {
        (acc[tag.category] = acc[tag.category] || []).push(tag);
        return acc;
      }, {});
    },
  },
  watch: {
    tagsSelected(newTags) {
      this.form.select = newTags;
    }
  },
  mounted() {
    this.getCarTags(this.carId)
      .catch(() => this.notLoadedError())
    this.getTagsOptions()
  },
  methods: {
    ...mapActions('tags', ['getTags', 'getCarTags']),
    async getTagsOptions() {
      this.getTags()
        .catch(() => this.notLoadedError())
    },
    setTags(values) {
      this.$emit('setTags', values);
    },
    notLoadedError() {
      this.error = true;
    },
    reloadWindow() {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
