<template>
  <b-form-select
    id="fleet-status"
    v-model="model"
    :disabled="isDisabled"
    :options="optionsListWithAcl"
    @input="setFleetStatus"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FleetStatusGroupAcl',
  props: {
    model: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    aclField: {
      type: String,
      required: false,
      default: 'ACL:FLEET:EDIT_STATUS',
    },
    disabled: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      statusInitial: '',
      cannotEdit: false,
    };
  },
  computed: {
    ...mapGetters('user', ['groups', 'region']),
    optionsListWithAcl: {
      get() {
        return this.options.map(option => ({ ...option, disabled: !this.checkAcl(option) }));
      },
      set(value) {
        this.options = value;
      },
    },
    isDisabled() {
      return this.disabled || this.cannotEdit;
    }
  },
  mounted() {
    this.statusInitial = this.model
  },
  methods: {
    setFleetStatus( values ) {
      this.$emit('setFleetStatus', values);
    },

    checkAcl( option ) {
      const isMexico = this.region === 'MX:DF:CIUDAD_DE_MEXICO';
      const hasPermissionGroup = this.groups.includes(`${this.aclField}:${option.value}`);

      const disableChangeStatus = this.options.find(el => el.value === this.model).cannotEdit
        && !this.groups.includes(`${this.aclField}:${this.model}`)

      if (disableChangeStatus) return !disableChangeStatus || option.value === this.model

      this.cannotEdit = option.value === this.statusInitial && option.cannotEdit && !hasPermissionGroup;

      return (
        !option.needAcl ||
        hasPermissionGroup ||
        option.value === this.statusInitial ||
        isMexico
      );
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
