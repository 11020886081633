var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-form-select", {
    attrs: {
      id: "fleet-status",
      disabled: _vm.isDisabled,
      options: _vm.optionsListWithAcl,
    },
    on: { input: _vm.setFleetStatus },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }