var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.error
    ? _c(
        "div",
        _vm._l(_vm.groupedTags, function (tags, category) {
          return _c(
            "div",
            { key: category },
            [
              _c("br"),
              _c("strong", [
                _vm._v(_vm._s(_vm.$t(`fleetProfile.tagsCategory.${category}`))),
              ]),
              _c("b-form-checkbox-group", {
                staticClass: "mt-2",
                attrs: { options: tags },
                on: { input: _vm.setTags },
                model: {
                  value: _vm.form.select,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "select", $$v)
                  },
                  expression: "form.select",
                },
              }),
            ],
            1
          )
        }),
        0
      )
    : _c(
        "div",
        [
          _c(
            "b-alert",
            { staticClass: "info", attrs: { show: "", variant: "warning" } },
            [
              _vm._v(" Não foi possivel carregar as Tags "),
              _c(
                "b-button",
                {
                  staticClass: "bg-warning",
                  attrs: { size: "sm" },
                  on: { click: _vm.reloadWindow },
                },
                [_c("i", { staticClass: "fa fa-refresh" })]
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }